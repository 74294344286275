import React, { useCallback, useContext } from 'react'
import '../../styles/HardwareStatusCheckPage.scss'
import Button from '@veneer/core/dist/scripts/button'
import {
  ANALYTICS,
  contentStackTypes,
  OnboardingCenterURLLookupTable,
  VENEER_TERTIARY_APPEARANCE
} from '../../store/Constants'
import useContentStack from '../../hooks/useContentStack'
import useHwStatusCheckTrees from '../../hooks/useHwStatusCheckTrees'
import useAnalytics from '../../hooks/useAnalytics'
import {
  HardwareStatusCheckEntry,
  HardwareStatusCheckPageData
} from './types/HardwareStatusCheckTypes'
import { ConfigContext } from '../../store/ConfigContext'
import { buildCdmEventTrees } from '../../utils/cdmHelper'

export const hwStatusCheckPageDataParser = (data?) => {
  if (!data) return null

  const pageData: HardwareStatusCheckPageData = {
    header: data.header,
    description: data.description,
    printerImage: {
      url: data?.printer_image?.url,
      description: data?.printer_image?.description
    },
    buttons: {
      viewInstructions: data.buttons.view_instructions,
      continueSetup: data.buttons.continue_setup
    }
  }

  return pageData
}

const HardwareStatusCheckPage = () => {
  const { pageData } = useContentStack<
    HardwareStatusCheckPageData,
    HardwareStatusCheckEntry
  >(contentStackTypes.hardware_status_check, hwStatusCheckPageDataParser)

  const {
    sessionContext,
    stack,
    closeServiceInstance,
    publishCdmEvents
  } = useContext(ConfigContext)
  const {
    actionPrintEngineSetup,
    suppliesPrivate,
    mediaConfig,
    calibrationStatus,
    queryStatus,
    ldResourcesCheck
  } = useHwStatusCheckTrees()
  const { fireScreenDisplayed, fireButtonClick } = useAnalytics(
    ANALYTICS.SCREENS.HARDWARE_STATUS_CHECK
  )

  const viewInstructionsClickHandler = useCallback(() => {
    fireButtonClick(ANALYTICS.BUTTONS.VIEW_INSTRUCTIONS)
    const destinationPath =
      OnboardingCenterURLLookupTable[stack] +
      sessionContext.device.productNumber
    window.open(destinationPath, '_blank')
  }, [stack, sessionContext.device.productNumber, fireButtonClick])

  const nextStageClickHandler = useCallback(() => {
    fireButtonClick(ANALYTICS.BUTTONS.CONTINUE_SETUP)
    if (ldResourcesCheck?.enabled) {
      publishCdmEvents(
        buildCdmEventTrees({
          trees: {
            actionPrintEngineSetup,
            suppliesPrivate,
            mediaConfig,
            calibrationStatus
          },
          queryStatus
        })
      )
    }
    closeServiceInstance()
  }, [
    ldResourcesCheck?.enabled,
    closeServiceInstance,
    fireButtonClick,
    publishCdmEvents,
    actionPrintEngineSetup,
    suppliesPrivate,
    mediaConfig,
    calibrationStatus,
    queryStatus
  ])

  fireScreenDisplayed()

  return (
    pageData && (
      <div className="container">
        <div className="content">
          <h3 data-testid="header" className="header">
            {pageData.header}
          </h3>
          <div className="descriptionContainer">
            <img
              data-testid="printer-image"
              className="printerImage"
              src={pageData.printerImage.url}
              alt={pageData.printerImage.description}
            />
            <p
              data-testid="description"
              className="contentText"
              dangerouslySetInnerHTML={{
                __html: pageData.description
              }}
            />
          </div>
          <div className="buttonsContainer">
            <Button
              data-testid="view-instructions-button"
              appearance={VENEER_TERTIARY_APPEARANCE}
              onClick={viewInstructionsClickHandler}
            >
              {pageData.buttons.viewInstructions}
            </Button>
            <Button
              data-testid="continue-setup-button"
              onClick={nextStageClickHandler}
            >
              {pageData.buttons.continueSetup}
            </Button>
          </div>
        </div>
      </div>
    )
  )
}

export default HardwareStatusCheckPage
