import { Stack } from '@jarvis/web-stratus-client'

export const VENEER_TERTIARY_APPEARANCE = 'ghost'

export interface ContentStackStackType {
  delivery_token: string
  env_name: string
}

export type ContentStackStacks = {
  [key: string]: ContentStackStackType
}

export const CONTENT_STACK_API_KEY = 'blt8a8e334f0a83fb21'

export const contentStackStacks: ContentStackStacks = {
  pie: {
    delivery_token: 'cs8e4d4c476111bdad9ef3a077',
    env_name: 'pie'
  },
  stage: {
    delivery_token: 'cs1d8958ab4c6be46a38350bc4',
    env_name: 'stage'
  },
  prod: {
    delivery_token: 'csf5dd829a1a24c27be970a285',
    env_name: 'prod'
  }
}

export interface ContentType {
  entry_uid: string
  content_type_uid: string
  printer_sku?: string
}

export type ContentStackTypes = {
  [key: string]: ContentType
}

export const contentStackTypes: ContentStackTypes = {
  hardware_status_check: {
    entry_uid: 'blt2be4bce232ac1047',
    content_type_uid: 'hardware_status_check'
  }
}

export const OnboardingCenterURLLookupTable: Record<Stack, string> = {
  [Stack.dev]: 'https://onboardingcenter.pie.portalshell.int.hp.com/',
  [Stack.pie]: 'https://onboardingcenter.pie.portalshell.int.hp.com/',
  [Stack.stage]: 'https://onboardingcenter.stage.portalshell.int.hp.com/',
  [Stack.prod]: 'https://start.hp.com/'
}

export const CDM_TREE_NAMES = {
  actionPrintEngineSetup: 'actionPrintEngine',
  suppliesPrivate: 'suppliesPrivate',
  mediaConfig: 'mediaConfig',
  calibrationStatus: 'calibrationStatus'
}

export const QUERY_ENUMS = {
  FAILED: 'failed',
  PENDING: 'pending',
  COMPLETE: 'complete'
}

export const ANALYTICS = {
  SCREENS: {
    HARDWARE_STATUS_CHECK: {
      screenName: 'HardwareStatusCheck',
      activity: 'HardwareSetup-v01',
      screenPath: '/HardwareStatusCheck/'
    }
  },
  BUTTONS: {
    CONTINUE_SETUP: 'ContinueSetupButton',
    VIEW_INSTRUCTIONS: 'ViewInstructionsButton'
  },
  VERSION: '1.5.0',
  ENVELOPE_VERSION: '1.4.0',
  EVENT_DETAIL_TYPE:
    'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
  EVENT_CATEGORY: 'simpleUi'
}
